import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
// import Idioma from '../Language/language';
import Menu from '../Menu';
import { Container, Header } from './styles';

export default function HeaderPrivacidade() {
  const { idioma } = useContext(LanguageContext);

  var title = ['Privacidade', 'Privacy', 'Privacidad'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          {title[idioma]} <hr />
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}

/*
//BANDEIRAS PARA TRADUÇÃO
*/
