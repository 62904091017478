import React from 'react';

export default function text() {
  var body = [
    //PORT
    `<p><b>O que diz a Lei?</b></p>
    ​
    <p>PRIVACIDADE</p>
    
    <p>A Constituição Federal, em seu art. 5º, inciso X, assegura a proteção à intimidade, à vida privada, à honra e à imagem das pessoas. 
    Todavia, é indiscutível que a convivência em sociedade pressupõe o compartilhamento de informações, sendo, portanto, essencial a 
    compatibilização do direito à privacidade com a necessidade de circulação de dados.</p>
    
    <p>LGPD​</p>
    
    <p>Nesse contexto, a Lei Geral de Proteção de Dados (LGPD), que entrou em vigor em setembro de 2020, visa assegurar a uniformidade 
    das atividades de tratamento de dados pessoais no Brasil, garantindo direitos e obrigações aos Agentes de Tratamento e aos 
    Titulares de Dados Pessoais. As sanções nela previstas serão aplicáveis a partir de 1º de agosto de 2021.</p>
    
    <p><b>O que é a cultura de proteção de dados que a HUB defende?</b></p>
    
    <p>Embora não tenha sido a primeira legislação a versar sobre proteção à privacidade no Brasil, a LGPD representou grande marco e 
    exige a mudança no comportamento de diversas organizações.</p>
    
    <p>Dentre as suas noções fundamentais, destaca-se a necessidade de conscientização de todos os colaboradores e terceiros acerca da 
    importância na forma de compartilhamento e armazenamento de dados.</p>
    
    <p>A população, em geral, costuma se expor demasiadamente, partilhando dados sensíveis sem os devidos cuidados. É importante que a 
    cultura de proteção de dados seja cada vez mais expandida.</p>
    
    <p>A conscientização, aliás, pode ser feita por meio de palestras, apresentações, videoconferências e até mesmo por meio de pequenos informes enviados aos colaboradores de tempos em tempos.​
    
    
    <p><b>Quais as bases legais para o tratamento de dados pessoais (art. 7º da Lei)?​</b></p>
    
    <ul>
      <li>Consentimento: livre, informado e inequívoco do titular;</li>
      <li>Cumprimento de obrigação legal ou regulatória;</li>
      <li>Execução de políticas publicas pela administração pública;</li>
      <li>Estudos por órgãos de pesquisa;</li>
      <li>Execução de contrato;</li>
      <li>Exercício regular de direitos em processos;</li>
      <li>Para proteção da vida ou da incolumidade física;</li>
      <li>Tutela de saúde, por profissionais da área;</li>
      <li>Interesse legítimo;​</li>
      <li>Proteção do crédito;</li>
    </ul>

    <p><b>E a base legal para tratamento de dados pessoais sensíveis (Art. 11 da Lei)?</b></p>

    <p>REGRA</p>
    <p>Consentimento específico, destacado e para finalidade específica;</p>

    <p>EXCEÇÂO</p>
    <ul>
      <li>Cumprimento de obrigação legal;</li>
      <li>Proteção da vida ou da incolumidade física;</li>
      <li>Exercício regular de direitos em contratos ou processos;</li>
      <li>Garantida da prevenção à fraude;</li>
      <li>Tutela da saúde, por profissionais da área;</li>
      <li>Execução de políticas públicas;</li>
      <li>Estudos por órgãos de pesquisas;</li>
    <ul> 

    <p><b>Quais os princípios para o tratamento de dados pessoais (Art. 6º da Lei)?​</b></p>

    <ul>
      <li>Boa fé</li>
      <li>Finalidade</li>
      <li>Adequação</li>
      <li>Necessidade</li>
      <li>Livre acesso</li>
      <li>Qualidade dos dados</li>
      <li>Transparência</li>
      <li>Segurança</li>
      <li>Prevenção</li>
      <li>Não discriminação</li>
      <li>Responsabilidade e prestação de contas</li>
    </ul>
    <p>Caso você deseje exercer alguns dos direitos que lhe são inerentes, listados nesta página, contate-nos por 
    meio do e-mail <b>dpo@hubfintech.com.br</b></p>
    `,

    

    //ENGLISH
    `<p><b>What does the General Data Protection Act (LGPD) say?</b></p>

    <p>PRIVACY</p>

    <p>The Federal Constitution, in its Article 5, item X, ensures the protection of intimacy, private life, honor and image of people. 
    However, it is unquestionable that coexistence in society presupposes the sharing of information, and, therefore, it is essential 
    that the right to privacy be compatible with the need to circulate data.</p>

    <p>LGPD</p>

    <p>In this context, the General Data Protection Act (LGPD), which came into force in September 2020, aims to ensure uniformity of 
    personal data processing activities in Brazil, guaranteeing rights and obligations to Processing Agents and Personal Data Holders. 
    The sanctions provided for therein will apply from August 1, 2021.</p>

    <p><b>What is the data protection culture that HUB defends?</p></b>

    <p>Although it was not the first legislation on privacy protection in Brazil, LGPD represented a major milestone and demands a change 
    in the behavior of several organizations.</p>

    <p>Among its fundamental notions, the need to raise awareness of all employees and third parties about the importance of data sharing 
    and storage is highlighted.</p>

    <p>The population, in general, usually exposes itself too much, sharing sensitive data without due care. It is important that the 
    culture of data protection be increasingly expanded.</p>

    <p>Awareness can be raised through lectures, presentations, videoconferences and even small reports sent to employees from time to 
    time.</p>

    <p><b>What are the legal grounds for processing personal data (Art. 7 of the Law)?</b></p>

    <ul>
      <li>Consent: free, informed and unequivocal of the holder;</li>
      <li>Compliance with legal or regulatory obligations;</li>
      <li>Execution of public policies by the public administration;</li>
      <li>Studies by research bodies;</li>
      <li>Execution of contract;</li>
      <li>Regular exercise of rights in processes; </li>
      <li>For the protection of life or physical safety;</li>
      <li>Health protection, by professionals in the area;</li>
      <li>Legitimate interest;</li>
      <li>Credit protection;</li>
    </ul>

    <p><b>What about the legal basis for processing sensitive personal data (Art. 11 of the Law)?</b></p>

    <p>RULES</p>

    <p>Specific, detached and for specific purpose consent;</p>

    <p>EXCEPTION</p>
    <ul>
      <li>Compliance with legal obligations;</li>
      <li>Protection of life or physical safety;</li>
      <li>Regular exercise of rights in contracts or processes;</li>
      <li>Guaranteed fraud prevention;</li>
      <li>Health protection, by professionals in the area;</li>
      <li>Execution of public policies;</li>
      <li>Studies by research bodies;</li>
    </ul>

    <p><b>What are the principles for the treatment of personal data (Art. 6 of the Law)?</b></p>
    <ul>
      <li>Good faith</li>
      <li>Purpose</li>
      <li>Adequacy</li>
      <li>Need</li>
      <li>Free access</li>
      <li>Data Quality</li>
      <li>Transparency</li>
      <li>Security</li>
      <li>Prevention</li>
      <li>Non discrimination</li>
      <li>Responsibility and accountability</li>
    </ul>
    
    <p>If you wish to exercise any of your rights listed on this page, please contact us at <b>dpo@hubfintech.com.br</b></p>
    `,

    //ESPANISH

    `
    <p><b>¿Qué dice la Ley General de Protección de Datos (LGPD)?</b></p>

    <p>PRIVACIDAD</p>

    <p>La Constitución Federal, en su artículo 5, inciso X, garantiza la protección de la intimidad, la vida privada, el honor y la imagen 
    de las personas. Sin embargo, es indiscutible que la convivencia en sociedad presupone compartir información y, por lo tanto, es 
    esencial que el derecho a la intimidad sea compatible con la necesidad de hacer circular los datos.</p>

    <p>LGPD</p>

    <p>En este contexto, la Ley General de Protección de Datos (LGPD), que entró en vigor en septiembre de 2020, tiene como objetivo 
    asegurar la uniformidad de las actividades de tratamiento de datos personales en Brasil, garantizando derechos y obligaciones a 
    los Responsables y Titulares de Datos Personales. Las sanciones previstas en el mismo se aplicarán a partir del 1 de 
    agosto de 2021.</p>

    <p><b>¿Cuál es la cultura de protección de datos que defiende HUB?</b></p>

    <p>Aunque no fue la primera legislación sobre protección de la privacidad en Brasil, la LGPD representó un hito importante y exige un 
    cambio en el comportamiento de varias organizaciones.</p>
    
    <p>Entre sus nociones fundamentales, se destaca la necesidad de concienciar a todos los empleados y a terceros sobre la importancia de 
    compartir y almacenar los datos.</p>

    <p>La población, en general, suele exponerse demasiado, compartiendo datos sensibles sin el debido cuidado. Es importante que la 
    cultura de la protección de datos se amplíe cada vez más.</p>

    <p>La concienciación también puede realizarse mediante charlas, presentaciones, videoconferencias e incluso pequeños informes enviados 
    a los empleados de vez en cuando.</p>

    <p><b>¿Cuáles son los fundamentos jurídicos del tratamiento de datos personales (art. 7 de la Ley)?</b></p>

    <ul>
      <li>Consentimiento: libre, informado e inequívoco del titular;</li>
      <li>Cumplimiento de una obligación legal o reglamentaria;</li>
      <li>Aplicación de las políticas públicas por parte de la administración pública;</li>
      <li>Estudios de organismos de investigación;</li>
      <li>Ejecución del contrato;</li>
      <li>Ejercicio regular de los derechos en los procesos; </li>
      <li>Para la protección de la vida o la seguridad física;</li>
      <li>Protección de la salud, a cargo de profesionales del área;</li>
      <li>Interés legítimo;</li>
      <li>Protección del crédito;</li>
    </ul>

    <p><b>¿Y la base legal para el tratamiento de datos personales sensibles (art. 11 de la Ley)?</b></p>

    <p>NORMAS</p>

    <p>Consentimiento específico e independiente para un fin concreto;</p>
    <p>EXCEPCIÓN</p>
    <ul>
      <li>Cumplimiento de una obligación legal;</li>
      <li>Protección de la vida o inocuidad física;</li>
      <li>Ejercicio regular de derechos en contratos o procesos;</li>
      <li>Prevención del fraude garantizada;</li>
      <li>Protección de la salud, por parte de los profesionales del área;</li>
      <li>Ejecución de políticas públicas;</li>
      <li>Estudios de organismos de investigación;</li>
    </ul>
    <p><b>¿Cuáles son los principios para el tratamiento de los datos personales (Art. 6 de la Ley)?</b></p>
    <ul>
      <li>Buena fe</li>
      <li>Objetivo</li>
      <li>Adecuación</li>
      <li>Necesidad</li>
      <li>Acceso gratuito</li>
      <li>Calidad de los datos</li>
      <li>Transparencia</li>
      <li>Seguridad</li>
      <li>Prevención</li>
      <li>No discriminación</li>
      <li>Responsabilidad y rendición de cuentas</li>
    </ul>
    
    <p>Si desea ejercer alguno de sus derechos enumerados en esta página, póngase en contacto con nosotros en <b>dpo@hubfintech.com.br</b></p>
      `,
  ];

  return body;
}
