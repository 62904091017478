import 'bootstrap/dist/css/bootstrap.css';
import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import CookiesEN from '../../../images/pdf/Cookies/Cookies_Policy.pdf';
import CookiesPT from '../../../images/pdf/Cookies/Política de Privacidade (Cookies).pdf';
import CookiesES from '../../../images/pdf/Cookies/Política_de_cookies_Español.pdf';
import FAQPT from '../../../images/pdf/FAQ/privacidade/FAQ.pdf';
import FAQEN from '../../../images/pdf/FAQ/privacidade/FAQ_EN.pdf';
import FAQES from '../../../images/pdf/FAQ/privacidade/FAQ_ES.pdf';
import PolicyPT from '../../../images/pdf/Termos/Politica_de_Privacidade.pdf';
import PolicyES from '../../../images/pdf/Termos/Politica_de_Privacidade_ES.pdf';
import PolicyEN from '../../../images/pdf/Termos/Politica_de_Privacidade_EN.pdf';
import { Container } from './styles';
import text from './text/text';

export default function BodyPrivacidade() {
  var body = text();

  const { idioma } = useContext(LanguageContext);

  var begin = [
    `Nesta página disponibilizamos informações relacionadas à Política de Privacidade, Segurança da Informação e  Lei Geral 
              de Proteção Dados (LGPD), viabilizando transparência em quaisquer informações compartilhadas nesse site.`,

    `On this page we provide information related to the Privacy Policy, Information Security and the General Law of Data 
              Protection (LGPD), enabling transparency in any information shared on this site.`,

    `En esta página proporcionamos información relacionada con la Política de Privacidad, la Seguridad de la Información y la 
              Ley General de Protección de Datos (LGPD), permitiendo la transparencia de cualquier información compartida en este sitio.`,
  ];
  var politica = [
    `Política de privacidade`,
    `Privacy Policy`,
    `Política de privacidad`,
  ];
  var cookies = [`Política de cookies`, `Cookie policy`, `Política de cookies`];

  var policy = [PolicyPT, PolicyEN, PolicyES];

  var FAQ = [FAQPT, FAQEN, FAQES];

  var Cookies = [CookiesPT, CookiesEN, CookiesES];

  return (
    <Container>
      <div>
        <p>{begin[idioma]}</p>
        <a href={policy[idioma]} download>
          <p>
            <a href={policy[idioma]} download>
              {politica[idioma]}
            </a>
          </p>
        </a>
        <a href={Cookies[idioma]} download>
          <p>
            <a href={Cookies[idioma]} download>
              {cookies[idioma]}
            </a>
          </p>
        </a>
        <a href={FAQ[idioma]} download>
          <p>
            <a href={FAQ[idioma]} download>
              FAQ
            </a>
          </p>
        </a>
        {Parser(body[idioma])}
      </div>
    </Container>
  );
}
