import React from 'react';
import Chat from '../components/Chat';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import HeaderPrivacidade from '../components/Privacidade';
import BodyPrivacidade from '../components/Privacidade/Body';
import SEO from '../components/seo';

export default function Privacidade() {
  return (
    <Layout>
      <SEO title="Privacidade" />
      <HeaderPrivacidade />
      <BodyPrivacidade />
      <Footer />
      <Chat />
    </Layout>
  );
}
